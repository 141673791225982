<template>
  <div
    ref="siteSearch"
    class="mobOnly:h-fullHeight  relative w-full
           mobOnly:flex flex-col block"
  >
    <div class="p-18 pb-0 md:hidden type-headline-2xl">
      {{ $lang('searchResources', 'heading') }}
      <button
        class="absolute right-12 top-20"
        @click="uiStore.closeAllModals()"
      >
        <img src="/zynicons/close.svg">
      </button>
    </div>
    <!-- search input -->
    <div
      class="relative basis-72 shrink-0
             mobOnly:p-12 "
    >
      <label
        class="block absolute top-[23px] left-20 md:top-2 md:left-0"
        for="headersearchInput"
      >
        <img
          src="/zynicons/search.svg"
          class="w-24 h-24"
          alt="Sök"
        >
      </label>
      <input
        id="headersearchInput"
        ref="searchInput"
        v-model="query"
        type="text"
        placeholder="Sök efter din zyn..."
        class="pl-36 w-full mobOnly:h-48 mobOnly:border border-neutral-light placeholder:type-tag-xs placeholder:uppercase placeholder:text-neutral-dark placeholder:relative placeholder:-top-2 font-zynSans !text-16"
        autocomplete="off"
        @focus="focusSearch"
      >
      <img
        v-if="loading"
        src="~assets/icons/spinner-dark.gif"
        class="h-16 w-16 absolute top-28 md:top-8 right-24 pointer-events-none"
      >
    </div>
    <!-- search result -->
    <div
      v-if="uiStore.isMobile || uiStore.showDesktopSearch"
      class="searchResults
    flex flex-col basis-full justify-between
    md:block md:absolute md:top-[45px] md:z-aboveHeader md:overflow-auto
    md:bg-neutral-lightest md:border-b md:border-l md:border-r border-neutral-light md:w-[max-content] min-w-full
    "
    >
      <div class="overflow-auto px-12 md:px-16">
        <!-- popular searches -->
        <div v-if="!query.length" class="mt-16 mb-28 md:mt-16">
          <p class="type-headline-sm mb-16">
            {{ $lang('searchResources', 'popularSearchesLabel') }}
          </p>
          <p
            v-for="item in popularLinks"
            :key="`searchLink-${item.text}`"
            class="mb-8 type-sm"
          >
            <NuxtLink
              :to="item.href"
              :target="item.target"
              :title="item.title"
              class="underline"
            >
              {{ item.text }}
            </NuxtLink>
          </p>
        </div>
        <!-- actual results -->
        <div v-if="searchResult && Object.keys(searchResult).length && query.length">
          <p class="type-sm text-neutral-dark mb-16">
            {{ searchResult.totalHits }} {{ $lang('searchResources', 'hits') }}
          </p>

          <div v-if="searchResult.products.length" class="mt-12">
            <div
              v-for="product in searchResult.products.slice(0, 4)"
              :key="product.id"
              class="md:flex justify-between items-start pb-24"
            >
              <NuxtLink
                :to="product.url"
                class="flex items-center"
              >
                <div class="flex-shrink-0">
                  <NuxtImg
                    preset="productImage"
                    loading="lazy"
                    class="w-40"
                    :src="`${product.imageUrl}?w=80`"
                    :alt="product.name"
                  />
                </div>
                <div class="mr-auto ml-12 pr-12 flex flex-col">
                  <p class="type-headline-sm">{{ product.name }}</p>
                  <span class="type-sm text-neutral-dark">{{
                    product.description
                  }}</span>
                </div>
              </NuxtLink>
              <div class="md:ml-16 md:w-[235px]">
                <SearchQuickBuy :product="product" />
              </div>
            </div>
          </div>

          <div v-if="searchResult.content.length" class="mt-32">
            <p class="type-headline-sm mb-16">
              {{ $lang('searchResources', 'content') }}
            </p>
            <p
              v-for="item in searchResult.content.slice(0, 3)"
              :key="item.url"
              class="type-sm mb-8"
            >
              <NuxtLink
                :to="item.url"
                class="underline"
              >
                {{ item.name }}
              </NuxtLink>
            </p>
          </div>
        </div>
      </div>
      <!-- show all results -->
      <div
        v-if="searchResult && Object.keys(searchResult).length && searchResult.totalHits > 0"
        class="p-12 basis-64 shrink-0"
      >
        <NuxtLink
          :to="searchResultUrl + '?query=' + query"
          class="btn btn-primary w-full"
        >
          {{ $lang('searchResources', 'showAllHits') }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui';
import { onClickOutside } from '@vueuse/core';
import { type IProduct, type IProductVariant, type ISearchResults } from '~/models/api-types';
import { useGlobalContentStore } from '~/store/globalContent';
import SearchQuickBuy from '~/components/search/SearchQuickbuy.vue';

const uiStore = useUiStore();
const config = useGlobalContentStore().config;
const searchResult = ref();
const { apiGet } = useApiFetch();
const { $lang } = useNuxtApp();

const siteSearch = ref<HTMLElement>();
const searchInput = ref<HTMLElement>();
const selectedVariantId = ref('');

onMounted(()=> {
  onClickOutside(siteSearch, () => {
    if (!uiStore.isMobile) {
      uiStore.setShowDesktopSearch(false);
    }
  });
  if (uiStore.isMobile) {
    searchInput.value?.focus();
  }

});

const query = ref('');
const loading = ref(false);

const purchaseEnabled = computed(() => {
  return config.currentMarket?.enablePurchases;
});

const inStock = computed(() => {
  return !!selectedVariant.value?.inStock;
});

const focusSearch = () => {
  if (!uiStore.isMobile) {
    uiStore.setShowDesktopSearch(true);
  }
};

const search = async() => {
  loading.value = true;
  const { currentMarket, language } = config;
  const searchTerm = `search/?language=${language}&countryCode=${currentMarket?.countryCode}&query=${query.value}`;
  searchResult.value = await apiGet<ISearchResults>(searchTerm);
  loading.value = false;

  window.dataLayer?.push({
    'event':'siteSearch',
    'searchTerm': searchTerm,
    'searchResults': searchResult.value ? searchResult.value.totalHits : null,
  });
};

const allVariantsOutOfStock = ((product: IProduct)=> {
  let hasVariantInStock = false;
  product.variants?.forEach((variant: IProductVariant) => {
    if (variant.inStock) {
      hasVariantInStock = true;
    }
  });
  return !hasVariantInStock;
});

const setVariant = (id: string) => {
  selectedVariantId.value = id;
};

watch(query, (newVal) => {
  if (newVal !== '') {
    search();
  }
});

const route = useRoute();

watch(() => route.fullPath, () => {
  query.value = '';
});

const popularLinks = computed(() => {
  const { currentMarket } = config;
  return currentMarket?.popularLinks ?? [];
});

const searchResultUrl = computed(() => {
  return config.currentMarket?.searchResultsPageUrl;
});

</script>
<style scoped lang="postcss">
.searchResults {
  max-height: calc(100dvh - 44px - 72px);
}
</style>

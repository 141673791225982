<template>
  <div
    class="mt-16 flex md:mt-0 relative"
  >
    <SelectorVariants
      v-if="product && product.variants.length > 0 && !allVariantsOutOfStock"
      class="search-selector-variants w-full mr-8"
      :class="{
        'opacity-60 pointer-events-none sm:hidden md:flex lg:hidden': allVariantsOutOfStock
      }"
      :product="product"
      :variants="product.variants"
      @change="setVariant"
    />
    <Button
      v-if="purchaseEnabled && inStock"
      :is-block="true"
      :text="actionText"
      :disabled="!inStock"
      :loading="isLoading"
      :hide-text-on-load="true"
      class="btn w-auto !h-36 md:!h-40"
      @click="addItemToCart"
    />
    <nuxt-link
      v-if="purchaseEnabled && !inStock"
      :to="productUrl"
      class="btn btn-secondary w-full"
      @click.native="productClick"
    >
      {{ $lang('productListingResources', 'getNotified') }}
    </nuxt-link>
  </div>
</template>
<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { useCartStore } from '~/store/cart';
import Button from '~/components/globals/Button.vue';
import SelectorVariants from '~/components/product/SelectorVariants.vue';
import {
  type IProductItem,
  type IProductVariant,
  PurchaseType
} from '~/models/api-types';
import { usePageContentStore } from '~/store/pageContent';
import * as Sentry from '@sentry/vue';
import { useVoyadoStore } from '~/store/voyado';
import { useFavouritesStore } from '~/store/favourites';
  
const globalContentStore = useGlobalContentStore();
const config = useGlobalContentStore().config;
const cartStore = useCartStore();
const { $lang } = useNuxtApp();
const route = useRoute();
const pageStore = usePageContentStore();
const voyado = useVoyadoStore();
const addedFavourites: Ref<string[]> = ref([]);
const favouritesStore = useFavouritesStore();
  
addedFavourites.value = favouritesStore.favourites;
  
interface IProps {
  product: IProductItem,
  isMobile?: boolean,
  useSchemaProps?: boolean,
  subscription?: boolean,
  listName?: string,
  index?: number,
  fullWidthImage?: boolean,
  quickbuySmall?: boolean
}
  
const props = withDefaults(defineProps<IProps>(), {
  useSchemaProps: true,
});

const selectedVariantId = ref('');
const isLoading = ref(false);
const spot = ref();
const productUrl = ref('');
  
const selectedVariant = computed(() => {
  return (
    props.product.variants.find(
      (variant) => variant.partNo === selectedVariantId.value
    ) || props.product.variants[0] || null
  );
});
  
const allVariantsOutOfStock = computed<boolean>(()=> {
  let hasVariantInStock = false;
  props.product.variants?.forEach((variant: IProductVariant) => {
    if (variant.inStock) {
      hasVariantInStock = true;
    }
  });
  return !hasVariantInStock;
});

const inStock = computed(() => {
  return !!selectedVariant.value?.inStock;
});
  
const actionText = computed(() => {
  return inStock.value
    ? $lang(
      'productListingResources',
      props.subscription ? 'subscribeButton' : 'purchase'
    )
    : $lang('productListingResources', 'outOfStock');
});
  
const purchaseEnabled = computed(() => {
  return !!config.currentMarket?.enablePurchases;
});
  
onBeforeMount(() => {
  productUrl.value = props.product.url!;
});
  
onMounted (() => {
  nextTick(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            window.dataLayer?.push({
              event: 'productImpressions',
              ecommerce: {
                currencyCode: selectedVariant.value?.currencyCode,
                impressions: [
                  {
                    list: props.listName ?? route.path,
                    ...selectedVariant.value?.gtmProperties,
                    variant: '1-pack',
                  },
                ],
              },
            });
            if (selectedVariant.value) {
              voyado.sendProductView(selectedVariant.value);
            }
            if (spot.value) {
              observer.unobserve(spot.value as Element);
            }
          }
        });
      },
      { rootMargin: '0px', threshold: 0.5, root: null }
    );
    if (spot.value) {
      observer.observe(spot.value as Element);
    }
  });
});
  
const productClick = () => {
  window.dataLayer?.push({
    event: 'productClick',
    ecommerce: {
      currencyCode: selectedVariant.value?.currencyCode,
      click: {
        actionField: {
          list: props.listName ?? route.path,
        },
        products: [
          {
            position: props.index,
            ...selectedVariant.value?.gtmProperties,
            variant: '1-pack',
          },
        ],
      },
    },
  });
};
  
const setVariant = (id: string) => {
  selectedVariantId.value = id;
  window.sessionStorage.removeItem(selectedVariant.value?.gtmProperties.id + '-packageSize');
  window.sessionStorage.setItem(selectedVariant.value?.gtmProperties.id + '-packageSize', selectedVariant.value?.packageSize);
};
  
const addItemToCart = async() => {
  if (selectedVariant.value) {
    try {
      isLoading.value = true;
      await cartStore.addItemToCart({
        item: selectedVariant.value,
        purchaseType: props.subscription
          ? PurchaseType.Subscription
          : PurchaseType.Standard,
        hidePopup: false,
      });
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      isLoading.value = false;
    }
  }
};
  
const isProductPage = computed(() => {
  return pageStore.pageType === 'ProductPage';
});
  
const product = props.product as IProductItem | null;
const selectedVariantValue = selectedVariant.value as IProductVariant | null;
const host = globalContentStore.host;
  
const jsonLd = {
  '@context': 'https://schema.org',
  '@type': 'Product',
  gtin: product?.ean,
  name: product?.name,
  description: product?.description,
  sku: selectedVariantValue?.gtmProperties.id,
  image: product?.imageUrl,
  brand: {
    '@type': 'Brand',
    name: selectedVariantValue?.gtmProperties.brand,
  },
  url: host + (product?.url ?? ''),
  offers: {
    '@type': 'Offer',
    priceCurrency: selectedVariantValue?.currencyCode,
    price: selectedVariantValue?.gtmProperties?.price
      ?.toString()
      ?.replace(',', '.'),
    availability: selectedVariantValue?.inStock
      ? 'https://schema.org/InStock'
      : 'https://schema.org/OutOfStock',
  },
} as any;
  
if (props.useSchemaProps && !isProductPage.value) {
  useJsonld(jsonLd);
}
</script>
<style scoped lang="postcss">
  .pouch-image {
    bottom: -41px;
    @apply max-h-112 absolute right-0 z-20;
  }
  
  .can-image {
    position: absolute;
    bottom: -20px;
    right: 0;
    left: 0;
    margin: 0 auto;
    height: 100%;
    width: 100%;
  }
  
  .tooltip-top::after {
    content: "";
    position: absolute;
    top: 100%;
    right: 24px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
  }
</style>
<style>
.search-selector-variants {
  .items {
    @apply md:min-w-auto md:w-max top-[45px] md:left-auto;
  }
}
</style>
  

import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["Articles","BrandPage","EventListingBlockQuery","NotFoundPage","SideBarBlocks","Start","UpcomingEvents","Content","ItemsInContentArea","AccordionBlock","ArticleTeaserBlock","BrandListingBlock","ComparisonStrengthBlock","ComparisonTableBlock","ComparisonTimelineBlock","ContactUsCategoryBlock","ContentWithIconsBlock","CookieDeclarationBlock","CountDownTimerCoverBlock","CountDownTimerPromoBlock","Cover2GridBlock","Cover3GridBlock","CoverBlock","EventListingBlock","FavouritesBlock","FindYourZynBlock","FormContainerBlock","GetSampleBlock","HeroBlock","HeroCarouselBlock","IconBlock","IconItemBlock","ImageBannerPromoBlock","IncompletePickAndMixBlock","LinkButtonListingBlock","MarqueeBannerBlock","NewsletterBlock","NotificationBlock","ProductCarouselBlock","ProductListFilterBlock","ProductPromo2GridBlock","ProductPromoBlock","ProductsBlock","PromoBlock","QuickSelectionBlock","SEOTextBlock","SampleProductsBlock","StatisticsBlock","StepsBlock","TakeOverBlock","TextCardsBlock","TextImageVideo2GridBlock","TextImageVideoBlock","TimelineBlock","WarningBlock","WysiwygBlock","ZynPromoBlock","ZynPromoGridBlock","AgeVerificationPage","ArticleListingPage","ArticlePage","AutoshipProductListingPage","CampaignPage","CheckoutConfirmationPage","CheckoutPage","ComplaintPage","ContactUsPage","CreateAccountPage","EventListingPage","EventPage","ForgotPasswordPage","FreeSamplePage","GeofenceRedirectPage","InformationPage","MaintenancePage","MarketLandingPage","MyPage","PickAndMixPage","ProductListingPage","ProductPage","ResetPasswordPage","SearchResultsPage","SidebarBlockPage","SignInPage","StandardBlockPage","StartPage","StoreLocatorPage","TrackingPage"]}
export const GqlArticles = (...params) => useGql()('Articles', ...params)
export const GqlBrandPage = (...params) => useGql()('BrandPage', ...params)
export const GqlEventListingBlockQuery = (...params) => useGql()('EventListingBlockQuery', ...params)
export const GqlNotFoundPage = (...params) => useGql()('NotFoundPage', ...params)
export const GqlSideBarBlocks = (...params) => useGql()('SideBarBlocks', ...params)
export const GqlStart = (...params) => useGql()('Start', ...params)
export const GqlUpcomingEvents = (...params) => useGql()('UpcomingEvents', ...params)
<template>
  <div
    class="alert-bar p-16 flex type-headline-xs"
    :class="{
      'bg-success-primary text-white': alertType === 'success',
      'bg-critical-light': alertType === 'error',
      'bg-neutral-lighter alert-info text-brand-dark': alertType === 'info',
    }"
  >
    <div
      v-if="alertType === 'error'"
      class="mr-8 bg-grey200 rounded-full flex justify-center items-center w-20 h-20 flex-shrink-0"
    >
      <fa
        :icon="['fas', alertType === 'error' ? 'times' : 'check']"
        class="text-micro"
        :class="{
          'text-critical-primary': alertType === 'error',
        }"
      />
    </div>
    <img
      v-else-if="alertType === 'success'"
      src="/zynicons/check.svg"
      class="w-16 h-16 mr-12 flex-shrink-0 mt-2"
    >
    <fa
      v-else
      :icon="['fal', 'info-circle']"
      class="text-base text-brand-dark mr-8"
    />
    <span v-html="text" />
  </div>
</template>
<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
const config = useGlobalContentStore().config;

const props = defineProps<{
  text: string,
  alertType: string,
  roundedNone?: boolean
}>();
</script>
<style scoped>
.text-micro {
  font-size: 12px;
}
</style>

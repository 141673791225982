<template>
  <div v-if="linkUrl">
    <nuxt-link :to="linkUrl" :class="classSelection">
      <div
        class="w-full h-full flex justify-center items-center"
        @click.self="createRipple"
      >
        {{ text }}
        <div
          v-if="(icon && !disabled) || loading"
          class="h-24 w-24 ml-12"
          :class="{ 'absolute right-24': isBlock }"
        >
          <img
            v-show="loading"
            :key="'~assets/icons/spinner.gif'"
            src="~assets/icons/spinner.gif"
            height="20"
            width="20"
          >
          <img
            v-show="!loading"
            :key="getPath()"
            :src="getPath()"
            height="20"
            width="20"
            class="object-contain"
          >
        </div>
      </div>
    </nuxt-link>
  </div>
  <div
    v-else
    :class="classSelection"
    @click="createRipple"
  >
    <span
      class="text transition-all duration-200 ease-in-out"
      :class="{ 'opacity-0': hideTextOnLoad && loading }"
    >{{ text }}</span>
    <div
      v-if="(icon && !disabled) || loading"
      class="h-20 w-20"
      :class="{
        'ml-12': !hideTextOnLoad,
        'absolute right-18 top-1/2 transform -translate-y-1/2 mt-px sm:mt-0':
          isBlock && !isNarrowIcon,
        'absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2':
          hideTextOnLoad,
      }"
    >
      <img
        v-show="loading"
        :key="'~assets/icons/spinner.gif'"
        src="~assets/icons/spinner.gif"
        height="20"
        width="20"
      >
      <img
        v-show="!loading"
        :key="getPath()"
        :src="getPath()"
        height="20"
        width="20"
        class="object-contain"
      >
    </div>
    <slot />
  </div>
</template>
<script lang="ts">
import { type PropType } from 'vue';
import { Sizes, Themes } from '~/constants/button-layouts';
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    text: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: String as PropType<keyof typeof Sizes>,
      required: false,
      default: Sizes.sm,
    },
    theme: {
      type: String as PropType<keyof typeof Themes>,
      required: false,
      default: Themes.primary,
    },
    isBlock: {
      type: Boolean,
      required: false,
      default: false,
    },
    linkUrl: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    isAnimatedIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNarrowIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideTextOnLoad: {
      type: Boolean,
      required: false,
      default: true,
    },
    smallPadding: {
      type: Boolean,
      required: false,
      default: false,
    },
    rippleEffect: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      Sizes,
      Themes,
    };
  },
  computed: {
    classSelection(): { [name: string]: boolean } {
      return {
        btn: true,
        'disabled': this.disabled,
        'btn--lg type-tag-xs btn-large': this.size === Sizes.lg,
        'btn--md type-tag-xs': this.size === Sizes.md,
        'btn--sm type-tag-xs': this.size === Sizes.sm,
        'btn--xs type-tag-xs': this.size === Sizes.xs,
        'btn--round type-tag-xs': this.size === Sizes.round,
        'pr-24':
          !this.isBlock && !!this.icon && !this.disabled && !this.loading,
        'btn-primary':
          this.theme === Themes.primary,
        'btn-secondary':
          this.theme === Themes.secondary && !this.disabled,
        'btn--dark-theme':
          this.theme === Themes.dark && !this.disabled,
        'btn--light-theme':
          this.theme === Themes.light && !this.disabled && !this.loading,
        'btn--dark-ghost-theme':
          this.theme === Themes.ghost && !this.disabled && !this.loading,
        'btn--light-ghost-theme':
          this.theme === Themes.lightGhost && !this.disabled && !this.loading,
        'btn--success':
          this.theme === Themes.success && !this.disabled && !this.loading,
        'btn--wide': this.isBlock,
        'px-24': this.smallPadding,
      };
    },
  },
  methods: {
    createRipple(event: any) {
      if (this.rippleEffect && !this.disabled && !this.loading) {
        const button = event.currentTarget as HTMLElement;

        const circle = document.createElement('span');
        const diameter = Math.max(button.clientWidth, button.clientHeight);
        const radius = diameter / 2;

        circle.style.width = circle.style.height = `${diameter}px`;
        circle.style.left = `${event.clientX - (button.offsetLeft + radius)}px`;
        circle.style.top = `${event.clientY - (button.offsetTop + radius)}px`;
        circle.classList.add('ripple');
        if (this.theme === Themes.dark || this.theme === Themes.ghost) {
          circle.classList.add('ripple-dark');
        } else {
          circle.classList.add('ripple-light');
        }

        const ripple = button.getElementsByClassName('ripple')[0];

        if (ripple) {
          ripple.remove();
        }

        button.appendChild(circle);
      }
    },
    getPath() {
      const suffix = this.isAnimatedIcon ? '.gif' : '.png';
      const icon = this.icon;
      if (icon) {
        return `/icons/${icon}${suffix}`;
      } else {
        return '';
      }
    },
  },
});
</script>
<style>
span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
}
span.ripple-dark {
  background-color: #ffffff34;
}
span.ripple-light {
  background-color: #00000008;
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
</style>

/* eslint-disable camelcase */

import { defineStore , storeToRefs } from 'pinia';
import { useGlobalContentStore } from '~/store/globalContent';
import { useVoyadoStore } from '~/store/voyado';
import * as Sentry from '@sentry/vue';
import type { ILoginResult, IUser } from '~/models/api-types';
import { useFavouritesStore } from './favourites';
import { useUiStore } from './ui';

export const useUserStore = defineStore('userStore', {
  state: () => ({
    isVerified: '',
    savedPartNo: '',
    loadedLogin: false,
    user: null as IUser | null,
  }),
  actions: {
    setSavedPartNo(payload: string) {
      this.savedPartNo = payload;
    },
    async logIn(userDetails: {
      email: string,
      password: string,
      rememberMe: boolean,
    }) {
      const config = useGlobalContentStore().config;
      const voyadoStore = useVoyadoStore();
      const { apiPost, lastError } = useApiFetch();
      const favouritesStore = useFavouritesStore();
      this.loadedLogin = false;
      
      const res = await apiPost<ILoginResult>(
        `user/session?countryCode=${config.currentMarket?.countryCode}&language=${config.language}`,
        userDetails
      );

      const globalContentStore = useGlobalContentStore();
      const { requestVerificationToken } = storeToRefs(globalContentStore);
      const runTimeConfig = useRuntimeConfig();
      const uiStore = useUiStore();
      const route = useRoute();
      
      if (requestVerificationToken) {
        requestVerificationToken.value = await $fetch<string>(`${runTimeConfig.public.apiUrl}website/xsrf/token`);
      }

      if (!lastError.value && res.success) {
        await voyadoStore.setContactIdFromEmail(userDetails.email);
        await this.checkIsVerified();

        uiStore.setShowLogin(false);

        // If the user has added a favorite product and is prompted to log in, add product to favourites on success
        if (this.savedPartNo) {
          await favouritesStore.addToFavourites(this.savedPartNo);
        }

        favouritesStore.fetchFavourites();
      }

      this.savedPartNo = '';
      document.body.classList.remove('locked');

      await globalContentStore.loadFromCms(route.fullPath);
      this.loadedLogin = true;

      return res;
    },
    async logOut() {
      const globalContentStore = useGlobalContentStore();
      const config = useGlobalContentStore().config;
      try {
        const res = await $fetch(`/api/user/session?countryCode=${config.currentMarket?.countryCode}&language=${config.language}`, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': globalContentStore.getAcceptLanguage,
            'RequestVerificationToken': globalContentStore.getTheAntiForgeryToken,
          },
        });

        window.location.href = config.currentMarket?.url || '/';
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    async checkIsVerified() {
      const config = useGlobalContentStore().config;
      const { apiGet } = useApiFetch();
      const query =  `ageverification/is-verified?countryCode=${config.currentMarket?.countryCode}&language=${config.language}`;
      const res = await apiGet(query);
      this.isVerified = res?.isVerified;
    },
    async fetchUser() {
      const config = useGlobalContentStore().config;
      const { currentMarket, language } = config;
      const { apiGet } = useApiFetch();
      const query = `user/account?language=${language}&countryCode=${currentMarket?.countryCode}`;
      const res = await apiGet<IUser>(query);
      if (res) {
        this.user = res;
      }
    },
  },
  getters: {
  },
});

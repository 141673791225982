<template>
  <div class="selector">
    <div
      ref="select"
      class="w-full flex h-36 sm:h-40 bg-white"
      @blur="open = false"
    >
      <div
        v-if="selected"
        class="selected relative cursor-pointer flex justify-between pr-32 bg-white w-[max-content] min-w-full items-center pl-12 border-grey400 border type-headline-xs"
        :class="{
          'open': open
        }"
        @click="toggleDropdown()"
      >
        <div v-if="selected.packageSize === 1 && variants.length > 1">
          {{ $lang('sharedResources', 'can') }}
        </div>
        <div v-else-if="selected.productType === ProductType.ManagedStructure" class="lowercase">
          1 Mixpaket
        </div>
        <div v-else-if="selected.productType !== ProductType.ManagedStructure && variants.length < 2">
          1 st
        </div>
        <div v-else>{{ selected.packageSize }}-pack</div>
        <div v-if="selected.price">
          <span v-if="selected.price">
            {{ removeDecimals(selected.price) }}
          </span>
        </div>
      </div>
      <div
        ref="selectItems"
        class="items top-[55px] mt-8 z-30 left-0 right-0 bg-white absolute border border-grey400 w-[max-content] min-w-full"
        :class="{
          hidden: !open,
          'left-auto': isOutsideViewport
        }"
      >
        <div
          v-for="(option, i) of variants"
          :key="i"
          class="flex justify-between px-12 bg-white w-full items-center h-36 sm:h-40 border-b border-grey400 last:border-none animate-all type-headline-xs"
          :class="{
            'pointer-events-none cursor-auto !bg-grey200 text-grey800': !option.inStock,
            'cursor-pointer hover:bg-grey200': option.inStock,
            'opacity-50': disabled
          }"
          tabindex="0"
          @click="
            selected = option;
            open = false;
            $emit('change', option.partNo);
          "
        >
          <div class="flex items-center">
            <div
              v-if="selected"
              class="w-20 h-20 rounded-full border flex items-center justify-center animate-all mr-8"
              :class="{
                'border-brand-dark shadow-border-brand-dark': selected.partNo === option.partNo && !disabled,
                'border-neutral-light': selected.partNo !== option.partNo || disabled,
              }"
            >
              <div v-if="selected.partNo === option.partNo && !disabled" class="bg-brand-dark h-8 w-8 rounded-full" />
            </div>
            <div v-if="option.packageSize" class="ml-20">
              <span v-if="option.packageSize === 1 && variants.length > 1">
                {{ $lang('sharedResources', 'can') }}
              </span>
              <span v-else-if="selected?.productType === ProductType.ManagedStructure" class="lowercase">
                1 mixpaket
              </span>
              <span v-else-if="selected?.productType !== ProductType.ManagedStructure && variants.length < 2">
                1 st
              </span>
              <span
                v-else
                :class="{
                  'line-through': !option.inStock
                }"
              >
                {{ option.packageSize }}-pack
              </span>
              <div
                v-if="option.pricePerCan"
                class="ml-2 inline type-xs text-neutral-dark"
              >
                ({{ option.pricePerCan }}<span class="lowercase">/{{ $lang('sharedResources', 'can') }}</span>)
              </div>
            </div>
          </div>
          <div v-if="option.price" class="ml-8">
            <span v-if="option.price">
              {{ removeDecimals(option.price) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue';
import useDetectOutsideClick from '~/composables/useDetectOutsideClick';
import {
  type IProductVariant,
  type IProductItem,
  ProductType
} from '~/models/api-types';

const selected = ref();
const selectedVariantId = ref();
const open = ref();
const selectItems = ref();
const isOutsideViewport = ref();

const props = defineProps<{
  variants: IProductVariant[],
  type?: string,
  product: IProductItem,
  disabled?: boolean,
}>();

onMounted(()=> {
  // Choose preselected variant if in stock, else choose first varient in stock
  const numberOfVariants = props.variants.length;
  let preferredVariant = props.variants.find((variant: IProductVariant) => variant.isPreferredVariant);

  if (preferredVariant && preferredVariant.inStock) {
    selectedVariantId.value = preferredVariant?.partNo;
  } else if (
    numberOfVariants > props.product.standardProductVariant - 1 &&
    props.variants[props.product.standardProductVariant - 1].inStock
  ) {
    selectedVariantId.value =
      props.variants[props.product.standardProductVariant - 1].partNo;
  } else {
    let currentIndex = numberOfVariants - 1;
    let foundStandard = false;
    do {
      if (currentIndex === 0) {
        selectedVariantId.value = props.variants[currentIndex].partNo;
        foundStandard = true;
      } else if (props.variants[currentIndex].inStock) {
        selectedVariantId.value = props.variants[currentIndex].partNo;
        foundStandard = true;
      } else {
        currentIndex--;
      }
    } while (!foundStandard);
  }

  selected.value = props.variants.find(
    (variant: IProductVariant) => variant.partNo === selectedVariantId.value
  ) || null;

  emit('change', selectedVariantId.value);
});

const select = ref();

useDetectOutsideClick(select, () => { 
  open.value = false;
});

const removeDecimals = (price: string) => {
  return price.replace(/(\d+),\d+\s*(\w+)/, '$1 $2');
};

const toggleDropdown = async() => {
  isOutsideViewport.value = false;
  open.value = !open.value;

  // Wait for DOM to update
  await nextTick();

  const dropdown = selectItems.value;
  const rect = dropdown.getBoundingClientRect();
  if (dropdown) {
    isOutsideViewport.value =
    rect.left < 0 || 
    rect.right > window.innerWidth;
  }
};

const emit = defineEmits<{
  (e: 'change', option: string): void,
}>();
</script>

<style scoped lang="postcss">
.selected::after {
  content: '';
  background-image: url('~/assets/icons/chevron-down-light.svg');
  height: 16px;
  width: 18px;
  @apply top-1/2 transform -translate-y-1/2 right-8 pointer-events-none absolute bg-contain bg-no-repeat;
}
</style>

<style>

.even-product-spot {
  .items {
    @apply right-0 left-auto;
  }
}

</style>

export default function useDateFormat() {
  const formatDate = (input: string, type: 'm' | 'd' | 'md' | 'hm' | 'full') => {
    let options = {} as Intl.DateTimeFormatOptions;

    switch (type) {
      case 'm':
        options = {
          month: 'short',
        };
        break;
      case 'd':
        options = {
          day: 'numeric',
        };
        break;
      case 'md':
        options = {
          month: 'short',
          day: 'numeric',
        };
        break;
      case 'hm':
        options = {
          month: undefined,
          hour: '2-digit',
          minute: '2-digit',
        };
        break;
      default:
        options = {
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        };
    }

    if (type === 'hm') {
      return new Date(input).toLocaleTimeString(
        'sv-se',
        options
      ).replace('.', '');
    }

    return new Date(input).toLocaleDateString(
      'sv-se',
      options
    ).replace('.', '');
  };

  const dateSpan = (start: string, end?: string, showTime = true) => {
    if (!end) {
      return formatDate(start, 'full');
    }

    const startDate = formatDate(start, 'md');
    const endDate = formatDate(end, 'md');

    const startTime = formatDate(start, 'hm');
    const endTime = formatDate(end, 'hm');

    if (startDate === endDate) {
      return showTime 
        ? `${startDate} ${startTime} - ${endTime}` 
        : `${startDate}`;
    }
    
    return showTime 
      ? `${startDate} ${startTime} - ${endDate} ${endTime}` 
      : `${startDate} - ${endDate}`;
  };

  return {
    formatDate,
    dateSpan,
  };

}

<template>
  <div>
    <div class="type-base-sm mb-16">
      {{ $lang('loginResources', 'formHeadingCreateAccount') }}
    </div>
    <AlertBar
      v-if="isAgeVerificationPage"
      :alert-type="'info'"
      :text="$lang('ageVerificationResources', 'createAccountToGetFreeSample')"
      :rounded-none="true"
      class="mb-16"
    />
    <div>
      <div class="frontend-form bg-white">
        <div class="frontend-form-row">
          <div class="frontend-form-cell">
            <TextInput
              :id="'seCreateAccountFirstName'"
              :placeholder="$lang('loginResources', 'formFirstName')"
              :type="'text'"
              :form-input="true"
              :error="firstNameError"
              @submit-input="firstNameInput"
            />
          </div>
          <div class="frontend-form-cell">
            <TextInput
              :id="'seCreateAccountLastName'"
              :placeholder="$lang('loginResources', 'formLastName')"
              :type="'text'"
              :form-input="true"
              :error="lastNameError"
              @submit-input="lastNameInput"
            />
          </div>
        </div>
        <div class="frontend-form-row">
          <div class="frontend-form-cell">
            <TextInput
              :id="'seCreateAccountEmail'"
              :placeholder="$lang('loginResources', 'email')"
              :type="'email'"
              :form-input="true"
              :error="emailError"
              :warning="emailWarning"
              :autocomplete="'email'"
              @submit-input="emailInput"
            />
          </div>
        </div>
        <div class="frontend-form-row">
          <div class="frontend-form-cell">
            <TextInput
              :id="'seCreateAccountPhone'"
              :placeholder="$lang('loginResources', 'formPhone')"
              :type="'tel'"
              :form-input="true"
              :error="phoneError"
              :autocomplete="'tel'"
              @submit-input="phoneInput"
            />
          </div>
        </div>
        <div class="frontend-form-row">
          <div class="frontend-form-cell">
            <TextInput
              :id="'seCreateAccountPassword'"
              :placeholder="$lang('loginResources', 'password')"
              :type="'password'"
              :autocomplete="'password'"
              :form-input="true"
              :error="passwordError"
              :is-password-input="true"
              @submit-input="passwordInput"
            />
          </div>
        </div>
        <div class="frontend-form-row">
          <div class="frontend-form-cell">
            <TextInput
              :id="'seCreateAccountConfirmPassword'"
              :placeholder="$lang('loginResources', 'formConfirmPassword')"
              :type="'password'"
              :autocomplete="'password'"
              :form-input="true"
              :error="confirmPasswordError"
              :is-password-input="true"
              @submit-input="confirmPasswordInput"
            />
          </div>
        </div>
        <div
          v-if="!hideNewsletterCheckbox"
          class="frontend-form-row"
        >
          <div class="flex-1 pt-16 pr-16 pl-16">
            <Checkbox
              v-if="shouldShowNewsletterBox"
              :checked="subscribeToNewsLetter ? true : false"
              :text="$lang('loginResources', 'newsletterSignupText')"
              @on-check="toggleSubscribeToNewsLetter"
            />
          </div>
        </div>
      </div>
    </div>
    <WysiwygWrapper
      v-if="privacyPolicy"
      :style-config="{ 'mt-8 type-xs': true }"
      :html="privacyPolicy"
    />
    <div
      :class="{
        'bg-white p-16 border-l border-r border-b border-border':
          isAgeVerificationPage,
        'mt-24': !isAgeVerificationPage,
      }"
    >
      <Button
        :is-block="true"
        :size="buttonMedium ? Sizes.md : Sizes.lg"
        :theme="Themes.dark"
        :text="$lang('loginResources', 'createAccountAction')"
        :loading="createAccountLoading"
        class="prevent-tilt"
        @click="onCreateAccount"
      />
    </div>

    <AlertBar
      v-if="errorMessage"
      class="mt-24"
      :alert-type="'error'"
      :text="errorMessage"
    />
  </div>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { useUserStore } from '~/store/user';
import emailMisspelled, { all } from 'email-misspelled';
import { Sizes, Themes } from '../constants/button-layouts';
import TextInput from './form-elements/TextInput.vue';
import Checkbox from './form-elements/Checkbox.vue';
import Button from './globals/Button.vue';
import WysiwygWrapper from './WysiwygWrapper.vue';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { storeToRefs } from 'pinia';
import { useUiStore } from '~/store/ui';
import { useFavouritesStore } from '~/store/favourites';

const globalContentStore = useGlobalContentStore();
const config = useGlobalContentStore().config;
const { apiPost, lastError } = useApiFetch();
const { $lang, $event } = useNuxtApp();
const uiStore = useUiStore();
const userStore = useUserStore();
const favouritesStore = useFavouritesStore();
const { preventNavigation } = storeToRefs(uiStore);
const { savedPartNo } = storeToRefs(userStore);

const route = useRoute();

const props = defineProps<{
  privacyPolicy?: string,
  navigateToUrl?: string
  buttonMedium?: boolean,
  preventNavigationProps?: boolean,
  hideNewsletterCheckbox?: boolean,
}>();

const emit = defineEmits<{
  (event: 'logged-in'): void,
}>();

const email = ref('');
const firstName = ref('');
const lastName = ref('');
const password = ref('');
const confirmPassword = ref('');
const phone = ref('');
const emailError = ref('');
const phoneError = ref('');
const firstNameError = ref('');
const lastNameError = ref('');
const passwordError = ref('');
const confirmPasswordError = ref('');
const emailWarning = ref('');
const subscribeToNewsLetter = ref(false);
const createAccountLoading = ref(false);
const errorMessage = ref();

const router = useRouter();

const shouldShowNewsletterBox = computed(() => {
  return !!config.currentMarket?.enableNewsletter;
});

const isAgeVerificationPage = computed(() => {
  return config.isAgeVerificationPage;
});

const firstNameInput = (input: string) => {
  firstName.value = input;
  firstNameError.value = '';
};
const lastNameInput = (input: string) => {
  lastName.value = input;
  lastNameError.value = '';
};

const passwordInput = (input: string) => {
  password.value = input;
  passwordError.value = '';
};
const confirmPasswordInput = (input: string) => {
  confirmPassword.value = input;
  confirmPasswordError.value = '';
};
const phoneInput = (input: string) => {
  phone.value = input;
  phoneError.value = '';
};

const emailInput = async(input: string) => {
  email.value = input;

  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input.trim()) && input) {
    const emailChecker = emailMisspelled({ domains: all });

    if (emailChecker(input).length > 0) {
      let warningSting = $lang(
        'validationErrorResources',
        'emailWarning'
      );

      warningSting = warningSting.replace(
        '{emailDomain}',
        emailChecker(input)[0].suggest
      );
      emailWarning.value = warningSting;
    } else {
      emailWarning.value = '';
    }
    fetchVoyadoUser();

    emailError.value = '';
  } else if (!input) {
    emailError.value = '';
    emailWarning.value = '';
  } else {
    emailWarning.value = '';
    emailError.value = $lang(
      'validationErrorResources',
      'invalidEmailShort'
    );
  }
};

const fetchVoyadoUser = async() => {
  const { currentMarket, language } = config;

  const voyadoData = await apiPost(
    `voyado/newsletter/status?language=${language}&countryCode=${currentMarket?.countryCode}`,
    {
      email: email.value,
    }
  );
  
  subscribeToNewsLetter.value = voyadoData?.hasSubscribedToNewsletter;
};

const toggleSubscribeToNewsLetter = (checked: boolean) => {
  subscribeToNewsLetter.value = checked;
};

const campaignSource = process.client ? sessionStorage.getItem('registrationsource') : null;

const onCreateAccount = async() => {
  errorMessage.value = false;
  const { currentMarket, language } = config;

  $event('hide-error-bar');

  if (!validateInput()) {
    return;
  }

  createAccountLoading.value = true;

  await apiPost(
    `user/account?language=${language}&countryCode=${currentMarket?.countryCode}`,
    {
      email: email.value,
      phone: phone.value,
      firstName: firstName.value,
      lastName: lastName.value,
      password: password.value,
      signupForNewsletter: subscribeToNewsLetter.value,
      countryCode: currentMarket?.countryCode,
      campaignSource,
    }
  );

  errorMessage.value = lastError.value;
  console.log('error message');
  console.log(errorMessage.value);
  if (errorMessage.value) {
    /* $event('trigger-error-bar', {
      errorMessage,
    }); */
    createAccountLoading.value = false;
  } else {
    window.dataLayer?.push({
      event: 'Registration',
      eventCategory: 'user',
      eventAction: 'newuser',
    });

    if (!props.preventNavigationProps && !preventNavigation.value) {
      window.location.href = props.navigateToUrl || '/';
    } else {
      uiStore.setShowLogin(false);
    }
    
    await globalContentStore.loadFromCms(route.fullPath);
    await fetchAntiForgeryToken();
    emit('logged-in');
    if (savedPartNo.value) {
      // If the user has added a favorite product before creating account, add product to favourites on success
      await favouritesStore.addToFavourites(savedPartNo.value);
    }
    createAccountLoading.value = false;
  };
};
const runTimeConfig = useRuntimeConfig();
const { requestVerificationToken } = storeToRefs(globalContentStore);
const fetchAntiForgeryToken = async() => {
  const result = await $fetch(`${runTimeConfig.public.apiUrl}website/xsrf/token`);
  requestVerificationToken.value = result;
};

const validateInput = () => {
  let isValid = true;
  if (!email.value && !emailError.value) {
    isValid = false;
    emailError.value = $lang(
      'validationErrorResources',
      'emptyFormError'
    );
  }
  if (!firstName.value) {
    isValid = false;
    firstNameError.value = $lang(
      'validationErrorResources',
      'emptyFormError'
    );
  }
  if (!lastName.value) {
    isValid = false;
    lastNameError.value = $lang(
      'validationErrorResources',
      'emptyFormError'
    );
  }
  if (!password.value || password.value.length < 8) {
    isValid = false;
    passwordError.value = $lang(
      'validationErrorResources',
      'invalidPassword'
    );
  }

  if (password.value !== confirmPassword.value) {
    isValid = false;
    confirmPasswordError.value = $lang(
      'validationErrorResources',
      'invalidPasswordConfirmation'
    );
  }

  if (phone.value) {
    const countryCode = config.currentMarket?.countryCode as any;

    let valid = false;
    try {
      valid = isValidPhoneNumber(phone.value, countryCode);
    } catch (e) {
      valid = false;
    }
    if (!valid) {
      phoneError.value = $lang(
        'validationErrorResources',
        'invalidPhone'
      );
    }
  }

  return isValid;
};
</script>

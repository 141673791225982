/**
 This store keeps the currently viewed page,
 globalContent.ts keeps stuff like header, footer, menus
 */
import { defineStore } from 'pinia';
import { IContent } from '~/models/content';

export const usePageContentStore = defineStore('pageContentStore', {
  state: () => ({
    page: null as IContent | null,
    pageTypeString: '',
  }),
  actions: {
    async setPageContent(page: string) {
      this.page = page;
      return false;
    },
  },
  getters: {
    pageType(state) {
      if (state.page) {
        return state.page.Content?.items[0]?.__typename;
      }
      return '';
    },
    hideNewsletterForThisPage(state) {
      if (state.page) {
        return state.page.Content?.items[0]?.HideNewsletter;
      }
    },
  },
});

<template>
  <div class="min-h-screen bg-cover bg-center" :style="style">
    <StickyNicotineWarning v-if="globalContentStore && globalContentStore.showStickyWarning" />
    <PageHeader
      v-if="pageData && headerConfig"
    />
    <div
      v-if="error?.statusCode === 404 && pageData"
      class="flex items-center justify-center min-h-[75vh]"
    >
      <div class="p-24 rounded bg-white max-w-full mx-32 w-460 my-160">
        <h4>{{ pageData.Heading }}</h4>
        <WysiwygWrapper
          :style-config="{ wysiwyg: true }"
          :html="pageData.Body"
          data-epi-edit="Body"
        />
        <a
          class="btn btn--lg btn--dark-theme mt-12 w-full btn--md"
          :href="pageData.LinkUrl"
        >{{ pageData.LinkText }}</a>
      </div>
    </div>
    <div v-else>
      <div class="pt-64 md:pt-128">
        <div v-if="errorCode === 403">
          <h1 class="type-headline-4xl text-brand-dark text-center mb-16">403 - Forbidden</h1>
          <p class="sub-heading">You do not have access to this page.</p>
        </div>
        <div v-else>
          <div class="header">
            <img
              class="image mx-auto"
              src="/icons/zyn-new-logo-blue.svg"
              alt="ZYN logo"
            >
            <h1 class="type-headline-4xl text-brand-dark text-center mt-48 mb-48">Error 500 - Something went wrong</h1>
          </div>
          <div class="flex items-center justify-center bg-brand-dark text-white flex-col md:flex-row">
            <div class="w-full md:w-500 p-32 pb-0 md:p-48">
              <h3 class="type-heading-lg">Why did this happen?</h3>
              <p class="type-sm">Yeah, well, a technical error has occured. We might have removed the page you were looking for, the link you clicked may have expired or maybe you just typed the wrong address in the address bar.</p>
              <p class="type-sm">Anyway, we are sincerly sorry for the bad coding and that this unfortunate event happened to you!</p>
            </div>
            <div class="w-full md:w-500 p-32 md:p-48">
              <h3 class="type-heading-lg">What can you do?</h3>
              <p class="type-sm">You can try to type the correct address again or go to <a href="/" class="underline">the startpage</a> or go back to <a href="javascript:window.history.back()" class="underline">the previous page  </a>.</p>     
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="environmentName === 'development' || environmentName === 'integration'">
      <div>Code: {{ error?.statusCode }}</div>
      <div>Message: {{ error?.statusMessage }}</div>
      <div class="overflow-auto">
        Stack: <div v-html="error?.stack" />
      </div>
      <pre>{{ error }}</pre>
    </div>
    <PageFooter
      v-if="pageData && footerConfig"
      ref="footer"
      :is-mobile="isMobile"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import WysiwygWrapper from '~/components/WysiwygWrapper.vue';
import { useGlobalContentStore } from '~/store/globalContent';
import PageHeader from '~/components/body/PageHeader.vue';
import PageFooter from '~/components/body/PageFooter.vue';
import { useUiStore } from './store/ui';
import StickyNicotineWarning from '~/components/body/StickyNicotineWarning.vue';
const headerConfig = useGlobalContentStore().header;
const footerConfig = useGlobalContentStore().footer;
const uiStore = useUiStore();
const { isMobile, isTablet } = storeToRefs(uiStore);

const props = defineProps({
  error: Object,
});

const globalContentStore = useGlobalContentStore();
const runTimeConfig = useRuntimeConfig();
const pageData = ref();
const config = useGlobalContentStore().config;
const route = useRoute();

let routeString = route.path;
if (routeString.endsWith('/')) {
  routeString = routeString.substring(0, routeString.length - 1);
}
const siteId = config.siteId;
const { data } = await useAsyncGql('NotFoundPage', { locale: config.language, siteId });

if (data.value && data.value.NotFoundPage?.items) {
  pageData.value = data.value.NotFoundPage?.items[0];
}
const style = computed(() => {
  if (pageData.value?.BackgroundImage?.Url) {
    return `background-image: url(${pageData.value?.BackgroundImage?.Url})`;
  } else {
    return '';
  }
});

const environmentName = computed (() => {
  return runTimeConfig.public.environmentName.toLocaleLowerCase();
});

const errorCode = computed(() => {
  return props.error?.statusCode;
});

</script>

<style scoped>
.not-found-container {
  min-height: calc(100vh - 200px);
}

.header {
    text-align: center;
    @apply p-40 bg-white;
}
.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.image {
  max-height: 56px;
}

.heading {
  font-size: 50px;
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;
}

.sub-heading {
  font-size: 22px;
  margin-bottom: 40px;
  margin-top: 0;
  text-align: center;
}

.list-heading {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 24px;
  margin-top: 0;
}

.list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
}

.list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  margin-bottom: 16px;
}

.list-icon {
  max-height: 22px;
  margin-right: 12px;
}

@media screen and (max-width: 600px) {
  .heading {
    font-size: 32px;
    margin-bottom: 16px;
  }

  .sub-heading {
    font-size: 18px;
  }

  .list-heading {
    font-size: 18px;
    margin-bottom: 16px;
  }

  .list-item {
    font-size: 18px;
  }

  .list-icon {
    max-height: 18px;
  }
}
</style>
